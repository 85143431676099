var resizeTimer = false;
window.addEventListener('scroll', onScroll);
window.addEventListener('resize', function() {
  if (resizeTimer !== false) { clearTimeout(resizeTimer); }
  resizeTimer = setTimeout(onResized, 200);
});
function onScroll() {
  imageParallax.onScroll();
}
function onResized() {
  imageParallax.refresh();
}

var accordion = {
  init: function() {
    this.accItem = document.getElementsByClassName('js-accordion');
    if (this.accItem.length === 0) { return; }
    
    this.timer = false;
    this.accData = [];
    this.refresh();
    for (var i = this.accItem.length; i--;) {
      this.accItem[i].firstElementChild.addEventListener('click', this.toggleBody);
      this.accItem[i].firstElementChild.addEventListener('transitionend', this.bubblingStop);
      this.accItem[i].addEventListener('transitionend', this.transitionEnd);
    }
    var _this = this;
    window.addEventListener('resize', function() {
      if (_this.timer !== false) { clearTimeout(_this.timer); }
      _this.timer = setTimeout(_this.refresh, 200);
    });
  },
  refresh: function() {
    var _this = accordion;
    for (var i = _this.accItem.length; i--;) {
      var curItem = _this.accItem[i],
          accHead = curItem.firstElementChild,
          accBody = accHead.nextElementSibling,
          headHeight = accHead.clientHeight,
          bodyHeight = accBody.clientHeight;
      
      if (curItem.classList.contains('active')) {
        curItem.style.height = headHeight + bodyHeight + 'px';
      } else {
        curItem.style.height = headHeight + 'px';
      }
      _this.accData[i] = {
        headH: headHeight,
        bodyH: bodyHeight
      };
    }
  },
  toggleBody: function() {
    var _this = accordion, item = this.parentNode;
    for (var i = _this.accItem.length; i--;) {
      if (_this.accItem[i] !== item) {
        if (_this.accItem[i].classList.contains('active')) {
          _this.toggleHeight(_this.accItem[i], _this.accData[i].headH, false);
        }
      } else {
        if (item.classList.contains('active')) {
          _this.toggleHeight(item, _this.accData[i].headH, false);
        } else {
          _this.toggleHeight(item, _this.accData[i].headH + _this.accData[i].bodyH, true);
        }
      }
    }
  },
  toggleHeight: function(elm, h, tgl) {
    elm.style.height = h + 'px';
    elm.classList.add('anima');
    tgl ? elm.classList.add('active') : elm.classList.remove('active');
  },
  transitionEnd: function() { this.classList.remove('anima'); },
  bubblingStop: function(e) { e.stopPropagation(); }
};
accordion.init();

var imageParallax = {
  elmsData: [],
  init: function() {
    this.paraElm = document.getElementsByClassName('js-imgPara');
    if (this.paraElm.length) { this.loadCheck(); }
  },
  loadCheck: function() {
    var _this = this, count = 0, img = [], imgLen = this.paraElm.length;
    for (var i = imgLen; i--;) {
      img[i] = new Image();
      var setSrc = this.paraElm[i].firstElementChild.getAttribute('src');
      img[i].src = setSrc;
      img[i].onload = function() {
        count++;
        if (count === imgLen) { _this.refresh(); }
      }
    }
  },
  onScroll: function() {
    if (this.elmsData.length === 0) { return; }
    var transform = this._transformProp();
    for (var i = this.paraElm.length; i--;) {
      var scrPos = window.pageYOffset;
      // MEMO: distance of window top position - the element position
      var imgShiftVal = (scrPos - this.elmsData[i].elmTop) * this.elmsData[i].ratio;
      this.elmsData[i].img.style[transform] = 'translate3d(' + this.elmsData[i].xShiftVal + ',' + imgShiftVal + 'px,0px)';
      
      // MEMO: update Yposition of the element
      var clientRect = this.paraElm[i].getBoundingClientRect();
      this.elmsData[i].elmTop = scrPos + clientRect.top;
    }
  },
  _transformProp: function() {
    var testEl = document.createElement('div');
    if (testEl.style.transform === null) {
      var vendors = ['Webkit', 'Moz', 'ms'];
      for (var vendor in vendors) {
        if (testEl.style[ vendors[vendor] + 'Transform' ] !== undefined) {
          return vendors[vendor] + 'Transform';
        }
      }
    }
    return 'transform';
  },
  getData: function() {
    if (this.paraElm.length === 0) { return; }
    var winH = window.innerHeight;
    for (var i = this.paraElm.length; i--;) {
      var elm = this.paraElm[i];
      var img = elm.firstElementChild;
      var clientRect = elm.getBoundingClientRect(),
          leftStyle = window.getComputedStyle(img, null).getPropertyValue('left'),
          elmH = elm.clientHeight,
          imgH = img.clientHeight,
          elmTop = window.pageYOffset + clientRect.top,
          elmBtm = (elmTop + elmH < winH) ? elmTop + elmH : winH,
          imgDiff = imgH - elmH,
          elmDiff = elmBtm - elmH;
      
      // MEMO: ratio = difference of imgH - elmH / window.innerHeight - elmH
      this.elmsData[i] = {
        img: img, elmTop: elmTop,
        xShiftVal: leftStyle !== 'auto' ? '-50%' : '0px',
        ratio: Math.abs(imgDiff / elmDiff) > 0.3 ? 0.3 : Math.abs(imgDiff / elmDiff)
      };
    }
    //console.log(this.elmsData);
  },
  destroy: function() {
    if (!this.elmsData.length) { return; }
    for (var i = this.paraElm.length; i--;) {
      this.paraElm[i].removeAttribute('style');
      this.elmsData[i].img.removeAttribute('style');
    }
    this.elmsData = [];
  },
  refresh: function() {
    this.getData();
    this.onScroll();
  }
};
imageParallax.init();
